<template>
  <div>
    <a-modal
      :visible="showModal"
      :footer="false"
      :width="390"
      class="modal-retur"
      @cancel="$emit('handleModal', false)"
    >
      <h4>
        Buat {{ $t('productAvailability.goodsReceipt') }}
      </h4>
      <hr />

      <div class="mt-4">
        Anda dapat {{ $t('productAvailability.goodsReceipt') }} berdasarkan Dokumen Referensi (PO, dll) yang telah dibuat atau tanpa Dokumen Referensi.
      </div>

      <div class="mt-3">
        <a-button class="inbound__button mx-2 ml-auto w-100" @click.prevent="createGr">
          Tanpa Dokumen Referensi
        </a-button>
        <a-button class="w-100 mt-3" type="primary" :loading="loading" @click="handleShow">
          Dengan Dokumen Referensi
        </a-button>
      </div>

      <!-- DATA --> <!-- LIST -->
      <div v-if="showTable">
        <div class="d-flex align-items-center mx-auto mb-2">
          Tipe Dokumen Inbound
        </div>
        <div class="d-flex align-items-center mx-auto">
          <div class="search-bar">
            <a-select
              v-model="dokumen"
              size="large"
              style="width: 100%"
              class="text-capitalize select-antd-default"
              :loading="loading"
              :disabled="loading"
            >
              <a-select-option
                :key="sort"
                value="id_po"
              >
                Purchase Order
              </a-select-option>
            </a-select>
          </div>
          <div class="search-bar ml-2">
            <a-input-search
              v-model="keyword"
              size="large"
              :placeholder="$t('product.searchBarPlaceholder')"
              :loading="loading"
              :disabled="loading"
              allow-clear
              @search="handleSearch"
            >
              <a-button slot="enterButton" type="danger" icon="search">
                {{ $t('utils.search') }}
              </a-button>
            </a-input-search>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'ModalRetur',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    pages: {
      type: String,
      default: '',
    },
    warehouse: {
      type: String,
      default: '',
    },
    // row: {
    //   type: Object,
    //   default: () => {},
    // },
    // params: {
    //   type: Object,
    //   default: () => {},
    // },
    // dataProductMaster: {
    //   type: Array,
    //   default: () => [],
    // },
    // sizeOption: {
    //   type: Array,
    //   default: () => [],
    // },
  },
  data: function() {
    return {
      showTable: false,
      keyword: '',
      dokumen: '',
    }
  },
  watch: {
    showModal: {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value === false) {
          this.visibleReject = value
          this.visibleUpdate = value
          this.isReason = ''
        }
      },
    },
  },
   methods: {
    handleShow() {
      this.showTable = !this.showTable
    },
    createGr() {
      this.$router.push({
        name: 'inventory.goods.receipt.create',
        query: {
          ...this.$route.query,
          warehouseId: this.warehouse,
        },
      })
    },
    changePage(param) {
      this.$emit('onPageChangeMaster', param)
    },
    changeSize(current, size) {
      this.$emit('onShowSizeChangeMaster', size)
    },
    handleSearchBar(e) {
      const value = e.target.value.trim()
      this.keyword = value
      this.$emit('onSearchMaster', value)
    },
  },
}
</script>

<style lang="scss">
.modal-retur {
  .list {
  border-radius: 5px;
  }
  .header-list {
    font-weight: 900;
    background: #E7ECEF;
    border-radius: 5px ;
    padding: 10px;
  }
  .data-list {
    padding: 5px 0;
    margin: 10px 0;
    background: #fff;
    border-radius: 5px ;
  }

  // ANT DESIGN
  // .ant-modal {
  //   width: 75% !important;
  // }
  .inbound__button {
    color: #ff0854 !important;
    background: #fff !important;
    border-color: #ff0854 !important;
  }
}
.inbound__button {
    color: #ff0854 !important;
    background: #fff !important;
    border-color: #ff0854 !important;
  }
.search-bar {
    position: relative;
    width: 534px;
    height: 44px;

    input[type='text'] {
      border: 1px solid #999;
      border-radius: 5px;
      width: 100%;
      outline: none;
      padding: 8px;
      box-sizing: border-box;
      transition: 0.3s;
    }

    input[type='text']:focus {
      border-color: rgb(5, 89, 203);
      box-shadow: 0 0 8px 0 rgb(5, 89, 203);
      color: #999;
    }

    input[type='text'] {
      padding-left: 40px;
    }

    input::placeholder {
      color: #999 !important;
      font-family: 'Poppins', sans-serif !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 21px !important;
    }
    .icon-form {
      position: absolute;
      left: 7px;
      top: 10px;
      color: #999;
      transition: 0.3s;
    }
  }
</style>